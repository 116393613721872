import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative'
  },
  chartWrapper: {
    height: 375,
    minWidth: 500
  },
}));

const LogsChart = (props) => {
  const {
    checkType,
    data: dataProp,
    labels,
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  const data = (canvas) => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    const gradient2 = ctx.createLinearGradient(0, 0, 0, 400);

    gradient.addColorStop(0, fade(theme.palette.secondary.main, 0.2));
    gradient.addColorStop(0.9, 'rgba(255,255,255,0)');
    gradient.addColorStop(1, 'rgba(255,255,255,0)');

    gradient2.addColorStop(0, fade(theme.palette.primary.main, 0.2));
    gradient2.addColorStop(0.9, 'rgba(255,255,255,0)');
    gradient2.addColorStop(1, 'rgba(255,255,255,0)');

    function getDataSets(dataObj) {
      let dataSets = [];

      dataSets.push({
        data: dataObj.primaryDataSet,
        // TODO: generate appropriate name off numDataSets
        label: 'Primary Data Set',
        backgroundColor: gradient,
        borderColor: theme.palette.secondary.main,
        pointBorderColor: '#FFFFFF',
        pointBorderWidth: 3,
        pointRadius: 6,
        pointBackgroundColor: theme.palette.secondary.main
      });

      if (dataObj.secondaryDataSet.length > 1) {
        dataSets.push({
          data: dataObj.secondaryDataSet,
          // TODO: generate appropriate name off numDataSets
          label: 'Secondary Data Set',
          backgroundColor: gradient2,
          borderColor: theme.palette.primary.main,
          pointBorderColor: '#FFFFFF',
          pointBorderWidth: 3,
          pointRadius: 6,
          pointBackgroundColor: theme.palette.primary.main
        });
      }

      return dataSets;
    };

    return {
      datasets: getDataSets(dataProp),
      labels: labels
    };
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    legend: {
      display: false
    },
    layout: {
      padding: 0
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          },
          // !FIXME: move these to generate different options obj. based off typeId
          ticks: generateTicksBasedOffCheckType(checkType, dataProp)
        }
      ]
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      caretSize: 10,
      yPadding: 20,
      xPadding: 20,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        title: () => {},
        label: (tooltipItem) => {
          // // TODO: REMOVE
          // console.log('toolTip - data', data);
          // console.log('toolTip - tooltipItem', tooltipItem);

          let label = `Value: ${tooltipItem.yLabel}`;

          // !FIXME more research required here, but need to separate labels for Equal Expression
          // !FIXME (cont.) for ref. https://github.com/chartjs/Chart.js/issues/1417

          // if (data.datasets.length > 1) {
          //   data.datasets.label = ['Table A', 'Table B']
          //   // if (tooltipItem.datasetIndex === 0) {
          //   //   label = `Table A: ${tooltipItem.yLabel}`;
          //   // } else {
          //   //   label = `Table B: ${tooltipItem.yLabel}`;
          //   // }
          // }

          return label;
        }
      }
    }
  };
//   callbacks: {
//     label: function(tooltipItem, data) {
//         var label = data.datasets[tooltipItem.datasetIndex].label || '';

//         if (label) {
//             label += ': ';
//         }
//         label += Math.round(tooltipItem.yLabel * 100) / 100;
//         return label;
//     }
// }

  function averageRounded(nums) {
    if (nums.length < 1) {
      return 0;
    }

    return Math.ceil(nums.reduce((a, b) => (a + b)) / nums.length);
  }

  function generateTicksBasedOffCheckType(id, { primaryDataSet, secondaryDataSet }) {
    // TODO: REMOVE
    console.group('generateTicksBasedOffCheckType()');
    console.log(primaryDataSet);
    console.log(secondaryDataSet);
    console.groupEnd();

    switch (id) {
      // Count
      case 1:
        let minVal = Math.min(...primaryDataSet);
        let maxVal = Math.max(...primaryDataSet);

        if (minVal === maxVal) {
          minVal = 0;
          maxVal = 2 * averageRounded(primaryDataSet);
        }

        return {
          padding: 20,
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: minVal,
          max: maxVal,
          maxTicksLimit: 7,
        };
      // Equal Expression
      case 2:
        return {
          padding: 20,
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          suggestedMin: 0,
          suggestedMax: 4,
          maxTicksLimit: 7,
        };
      // // Threshold
      // case 3:
      //   return {
      //     padding: 20,
      //     fontColor: theme.palette.text.secondary,
      //     beginAtZero: true,
      //     min: 0,
      //     max: 2 * averageRounded(primaryDataSet),
      //     maxTicksLimit: 7,
      //   };
      // Assert
      case 4:
        return {
          padding: 20,
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: -1,
          max: 3,
          maxTicksLimit: 7,
        };
      default:
        return {
          padding: 20,
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: 0,
          max: 2 * averageRounded(primaryDataSet),
          maxTicksLimit: 7,
        };
    }
  }

  return (
    <Card
      className={classes.root}
    >
      <CardHeader
        title="Logs Chart"
      />
      <Divider />
      <CardContent className={classes.content}>
        <div
          className={classes.chartWrapper}
        >
          <Line
            data={data}
            options={options}
          />
        </div>
      </CardContent>
    </Card>
  );
};

LogsChart.propTypes = {
  className: PropTypes.string,
  // data: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired
};

export default LogsChart;
