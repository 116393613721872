import { cloneDeep } from 'lodash';

// !FIXME: move to shared
import defaultCheckFormValues from 'views/Check/components/CheckForm/helpers/default-form-values.js';

//! TODO: EXPORT THIS (duplicated in form)
const frequencyMarks = [
  {
    value: 1,
    label: '30m',
  },
  {
    value: 2,
    label: '1h',
  },
  {
    value: 3,
    label: '2h',
  },
  {
    value: 4,
    label: '3h',
  },
  {
    value: 5,
    label: '4h',
  },
  {
    value: 6,
    label: '5h',
  },
  {
    value: 7,
    label: '6h',
  },
  {
    value: 8,
    label: '12h',
  },
  {
    value: 9,
    label: '1d',
  },
  {
    value: 10,
    label: '2d',
  },
  {
    value: 11,
    label: '3d',
  },
  {
    value: 12,
    label: '4d',
  },
  {
    value: 13,
    label: '5d',
  },
  {
    value: 14,
    label: '1w',
  },
  {
    value: 15,
    label: '2w',
  },
];

// TODO: this should be extended later to use a map of keys
function cloneThenTransformKeysFromCamelCase(formObject) {
  const clone = cloneDeep(formObject);

  clone.dataSource.freshness.group_by = clone.dataSource.freshness.groupBy;
  clone.dataSource.equalExpression.tableA.group_by = clone.dataSource.equalExpression.tableA.groupBy;
  clone.dataSource.equalExpression.tableB.group_by = clone.dataSource.equalExpression.tableB.groupBy;
  clone.dataSource.crossDataSourceEqualExpression.tableA.group_by = clone.dataSource.crossDataSourceEqualExpression.tableA.groupBy;
  clone.dataSource.crossDataSourceEqualExpression.tableB.group_by = clone.dataSource.crossDataSourceEqualExpression.tableB.groupBy;
  clone.dataSource.threshold.group_by = clone.dataSource.threshold.groupBy;

  // additional transform for Case 5 (more than just a casing change)
  clone.dataSource.crossDataSourceEqualExpression.tableA.connection = {
    id: Number(clone.dataSource.crossDataSourceEqualExpression.tableA.connectionId),
    type_id: Number(clone.dataSource.crossDataSourceEqualExpression.tableA.connectionTypeId)
  };
  clone.dataSource.crossDataSourceEqualExpression.tableB.connection = {
    id: Number(clone.dataSource.crossDataSourceEqualExpression.tableB.connectionId),
    type_id: Number(clone.dataSource.crossDataSourceEqualExpression.tableB.connectionTypeId)
  };

  delete clone.dataSource.freshness.groupBy;
  delete clone.dataSource.equalExpression.tableA.groupBy;
  delete clone.dataSource.equalExpression.tableB.groupBy;
  delete clone.dataSource.crossDataSourceEqualExpression.tableA.groupBy;
  delete clone.dataSource.crossDataSourceEqualExpression.tableB.groupBy;
  delete clone.dataSource.crossDataSourceEqualExpression.tableA.connectionId;
  delete clone.dataSource.crossDataSourceEqualExpression.tableA.connectionTypeId;
  delete clone.dataSource.crossDataSourceEqualExpression.tableB.connectionId;
  delete clone.dataSource.crossDataSourceEqualExpression.tableB.connectionTypeId;
  delete clone.dataSource.threshold.groupBy;

  return clone;
}

function getRunCountFromMapValue(mapValue) {
  const selectedFrequency = frequencyMarks.filter((frequency) => {
    return frequency.value === mapValue;
  })[0];
  const selectedFrequencyLabel = selectedFrequency.label;
  const selectedFrequencyLabelLength = selectedFrequencyLabel.length;

  const selectedFrequencyCount = selectedFrequencyLabel.slice(0, selectedFrequencyLabelLength - 1);

  return Number(selectedFrequencyCount);
}

function getRunFrequencyFromMapValue(mapValue) {
  const selectedFrequencyAbbreviationIntervalMap = {
    'm': 'minute',
    'd': 'day',
    'h': 'hour',
    'w': 'week'
  };
  const selectedFrequency = frequencyMarks.filter((frequency) => {
    return frequency.value === mapValue;
  })[0];
  const selectedFrequencyLabel = selectedFrequency.label;
  const selectedFrequencyLabelLength = selectedFrequencyLabel.length;

  const selectedFrequencyInterval = selectedFrequencyLabel.slice(selectedFrequencyLabelLength - 1);

  return selectedFrequencyAbbreviationIntervalMap[selectedFrequencyInterval];
}

function mungeRunMapValueFromSchedule(schedule) {
  const selectedFrequencyIntervalMap = {
    'minute': 'm',
    'day': 'd',
    'hour': 'h',
    'week': 'w'
  };
  const selectedFrequencyAbbreviation = selectedFrequencyIntervalMap[schedule.run_frequency];
  const runValueLabel = `${schedule.run_count}${selectedFrequencyAbbreviation}`;

  const selectedFrequency = frequencyMarks.filter((frequency) => {
    return frequency.label === runValueLabel;
  });

  if (selectedFrequency.length) {
    return selectedFrequency[0].value;
  } else {
    //? NOTE: this is only to catch the unmapped values from prior implementations, i.e.
    //? (cont.) '1 minute', etc.; the value of 1 here will end up pre-selecting '30m'
    return 1;
  }
}

export const transformDataForCheckPost = (checkFormData) => {
  // TODO: do this for create / others
  const clone = cloneThenTransformKeysFromCamelCase(checkFormData);
  const transformedCheckFormData = {};
  const comparisonOperatorToTextMap = {
    '>': 'gt',
    '<': 'lt',
    '>=': 'gte',
    '<=': 'lte',
    '!=': 'ne',
    '==': 'eq'
  };

  transformedCheckFormData.name = clone.checkName;
  transformedCheckFormData.type_id = Number(clone.checkType);
  transformedCheckFormData.connection_id = Number(clone.connectionId);

  transformedCheckFormData.meta = [];

  transformedCheckFormData.schedule = {};
  transformedCheckFormData.schedule.run_count = getRunCountFromMapValue(clone.schedule.runMapValue);
  transformedCheckFormData.schedule.run_frequency = getRunFrequencyFromMapValue(clone.schedule.runMapValue);
  transformedCheckFormData.schedule.fail_count = clone.schedule.failCount;

  transformedCheckFormData.notifications = transformNotificationsArray(
    clone.notifications,
    clone.notificationMessage,
    clone.slackApiKey,
    clone.pagerdutyApiKey
  );

  switch (Number(checkFormData.checkType)) {
    case 1:
      transformedCheckFormData.meta.push(clone.dataSource.freshness);
      break;
    case 2:
      transformedCheckFormData.meta.push(clone.dataSource.equalExpression.tableA);
      transformedCheckFormData.meta.push(clone.dataSource.equalExpression.tableB);
      break;
    case 3:
      clone.dataSource.threshold.threshold = {};
      clone.dataSource.threshold.expression = {};

      const comparisonOperatorText = comparisonOperatorToTextMap[clone.dataSource.threshold.comparisonOperator]
      clone.dataSource.threshold.threshold.value = Number(clone.dataSource.threshold.thresholdValue);
      clone.dataSource.threshold.expression.value = comparisonOperatorText;

      delete clone.dataSource.threshold.thresholdValue;
      delete clone.dataSource.threshold.comparisonOperator;

      transformedCheckFormData.meta.push(clone.dataSource.threshold);
      break;
    case 4:
      transformedCheckFormData.meta.push(clone.dataSource.assert);
      break;
    case 5:
      transformedCheckFormData.connection_id = Number(clone.dataSource.crossDataSourceEqualExpression.tableA.connection.id);

      transformedCheckFormData.meta.push(clone.dataSource.crossDataSourceEqualExpression.tableA);
      transformedCheckFormData.meta.push(clone.dataSource.crossDataSourceEqualExpression.tableB);
      break;
    default:
      return;
  }

  return transformedCheckFormData;
};

function transformNotificationsArray(
  formNotificationsArray,
  message,
  slackApiKeyString,
  pagerdutyApiKeyString
) {
  const transformedNotifications = formNotificationsArray.map((notification) => {
    let slackApiKey = null;
    let pagerdutyApiKey = null;

    if (Number(notification.typeId) === 2) {
      slackApiKey = slackApiKeyString;
    }

    if (Number(notification.typeId) === 4) {
      pagerdutyApiKey = pagerdutyApiKeyString;
    }
  
    return {
      "id": notification.id,
      "type_id": notification.typeId,
      "status": notification.status,
      "email": notification.email,
      "slack_channel": notification.slackChannel,
      "webhook_method": notification.webhook.method,
      "webhook_url": notification.webhook.url,
      "message": message,
      "slack_key": slackApiKey,
      "pagerduty_key": pagerdutyApiKey
    }
  });

  return transformedNotifications;
}

export const mungeToCheckForm = (rawCheckData) => {
  console.group('rawCheckData');
  console.log(rawCheckData);
  console.groupEnd();
  const munged = cloneDeep(defaultCheckFormValues);

  munged.id = rawCheckData.id;

  munged.checkName = rawCheckData.name;
  munged.connectionId = rawCheckData.connection_id;
  munged.checkType = rawCheckData.type_id;

  munged.schedule.runMapValue = mungeRunMapValueFromSchedule(rawCheckData.schedule);
  munged.schedule.failCount = rawCheckData.schedule.fail_count;

  munged.notifications = mungeNotificationsArray(rawCheckData.notifications);
  //? NOTE: assuming _all_ notifications will share the same message here...
  munged.notificationMessage = rawCheckData.notifications.length > 0 ? rawCheckData.notifications[0].message : '';

  switch (rawCheckData.type_id) {
    case 1:
      munged.dataSource.freshness = transformRawCheckFreshnessObj(munged, rawCheckData.meta);
      break;
    case 2:
      munged.dataSource.equalExpression = transformRawCheckExpressionsObj(munged, rawCheckData.meta);
      break;
    case 3:
      munged.dataSource.threshold = transformRawCheckThresholdObj(munged, rawCheckData.meta);
      break;
    case 4:
      munged.dataSource.assert.query = rawCheckData.meta[0].query;
      break;
    case 5:
      munged.dataSource.equalExpression = transformRawCheckCrossExpressionsObj(munged, rawCheckData.meta);
      break;
    default:
      return;
  }

  return munged;
};

function mungeNotificationsArray(notificationArray) {
  const formattedNotifications = notificationArray.map((notification) => {
    return {
      id: notification.id,
      name: notification.name,
      typeId: notification.type_id,
      email: notification.email,
      webhook: {
        method: notification.webhook_method || 'post',
        url: notification.webhook_url
      },
      slackChannel: notification.slack_channel,
      status: notification.status
    };
  });

  return formattedNotifications;
}

// TODO: move all transforms, also could prob. utilize Object.assign
// !FIXME: these bool values are coming back as strings, hence the inline ternary conversion
function transformRawCheckFreshnessObj({ dataSource }, metaArray){
  const metaObj = metaArray[0];

  dataSource.freshness.select = metaObj.select;
  dataSource.freshness.from = metaObj.from;
  dataSource.freshness.where = metaObj.where.toString() === 'true' ? true : false;
  dataSource.freshness.groupBy = metaObj.group_by.toString() === 'true' ? true : false;

  return dataSource.freshness;
}

function transformRawCheckExpressionsObj({ dataSource }, metaArray){
  const tableA = metaArray[0];
  const tableB = metaArray[1];

  dataSource.equalExpression.tableA.select = tableA.select;
  dataSource.equalExpression.tableA.from = tableA.from;
  dataSource.equalExpression.tableA.where = tableA.where.toString() === 'true' ? true : false;
  dataSource.equalExpression.tableA.groupBy = tableA.group_by.toString() === 'true' ? true : false;

  dataSource.equalExpression.tableB.select = tableB.select;
  dataSource.equalExpression.tableB.from = tableB.from;
  dataSource.equalExpression.tableB.where = tableB.where.toString() === 'true' ? true : false;
  dataSource.equalExpression.tableB.groupBy = tableB.group_by.toString() === 'true' ? true : false;

  return dataSource.equalExpression;
}

function transformRawCheckThresholdObj({ dataSource }, metaArray){
  const metaObj = metaArray[0];
  const expressionToComparisonOperatorMap = {
    'gt': '>',
    'lt': '<',
    'gte': '>=',
    'lte': '<=',
    'ne': '!=',
    'eq': '=='
  };
  const comparisonOperator = expressionToComparisonOperatorMap[metaObj.expression.value];

  dataSource.threshold.select = metaObj.select;
  dataSource.threshold.from = metaObj.from;
  dataSource.threshold.where = metaObj.where.toString() === 'true' ? true : false;
  dataSource.threshold.groupBy = metaObj.group_by.toString() === 'true' ? true : false;
  dataSource.threshold.thresholdValue = metaObj.threshold.value;
  dataSource.threshold.comparisonOperator = comparisonOperator;

  return dataSource.threshold;
}

function transformRawCheckCrossExpressionsObj({ dataSource }, metaArray){
  const tableA = metaArray[0];
  const tableB = metaArray[1];

  dataSource.crossDataSourceEqualExpression.tableA.connectionId = tableA.connection.id;
  dataSource.crossDataSourceEqualExpression.tableA.connectionTypeId = tableA.connection.type_id;
  dataSource.crossDataSourceEqualExpression.tableA.select = tableA.select;
  dataSource.crossDataSourceEqualExpression.tableA.from = tableA.from;
  dataSource.crossDataSourceEqualExpression.tableA.where = tableA.where.toString() === 'true' ? true : false;
  dataSource.crossDataSourceEqualExpression.tableA.groupBy = tableA.group_by.toString() === 'true' ? true : false;

  dataSource.crossDataSourceEqualExpression.tableB.connectionId = tableB.connection.id;
  dataSource.crossDataSourceEqualExpression.tableB.connectionTypeId = tableB.connection.type_id;
  dataSource.crossDataSourceEqualExpression.tableB.select = tableB.select;
  dataSource.crossDataSourceEqualExpression.tableB.from = tableB.from;
  dataSource.crossDataSourceEqualExpression.tableB.where = tableB.where.toString() === 'true' ? true : false;
  dataSource.crossDataSourceEqualExpression.tableB.groupBy = tableB.group_by.toString() === 'true' ? true : false;

  return dataSource.crossDataSourceEqualExpression;
}
