import { colors } from '@material-ui/core';
import { darken, lighten } from '@material-ui/core/es/styles/colorManipulator';

const white = '#FFFFFF';
const black = '#000000';
const darkGray = '#20212C';
const darkNavy = '#0C1E30';
const offWhite = '#E5E8EA';
// const blue = '#3F46F3';
// const teal = '#00C4A2';
const blue = '#20A4BB'; // new theme primary
const aqua = '#01E4DA'; // new theme secondary

export default {
  black: black,
  white: white,
  darkGray: darkGray,
  primary: {
    contrastText: white,
    dark: darken(blue, 0.10),
    main: blue,
    light: lighten(blue, 0.10)
  },
  secondary: {
    contrastText: white,
    dark: darken(aqua, 0.20),
    main: darken(aqua, 0.10),
    light: aqua
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: '#F4F6F8',
    paper: white
  },
  divider: colors.grey[200],
  navigation: {
    background: darken(darkNavy, 0.20),
    text: offWhite,
    hover: 'rgba(250, 251, 255, 0.2)',
    selected: blue
  },
  checkStatus: {
    passing: {
      fill: 'rgba(0, 200, 83, 0.7)',
      border: 'rgba(0, 200, 83, 1)'
    },
    failing: {
      fill: 'rgba(213, 0, 0, 0.7)',
      border: 'rgba(213, 0, 0, 1)'
    }
  }
};
